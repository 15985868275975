import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, currentLocale = 'hello', reportTitle, newDatas, vm, search, searchResulttwo, grandTotal, one, totalresult) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = [
          [
            { text: vm.$t('elearning_config.fiscal_year') + ' : ' + (search.fiscal_year_id !== 0 ? (currentLocale === 'en' ? searchResulttwo.fiscal_year : searchResulttwo.fiscal_year_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_demandDIS.mill_name') + ' : ' + (search.mill_info_id !== 0 ? (currentLocale === 'en' ? searchResulttwo.mill_name : searchResulttwo.mill_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_report.subzone_office_name') + ' : ' + (search.subzone_office_id !== 0 ? (currentLocale === 'en' ? searchResulttwo.subzone_office_name : searchResulttwo.subzone_office_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_report.center') + ' : ' + (search.center_id !== 0 ? (currentLocale === 'en' ? searchResulttwo.center_name : searchResulttwo.center_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        var rowData = [
          [{ text: vm.$t('bsri_report.sl'), rowSpan: 4, margin: [20, 20, 0] }, { text: vm.$t('bsri_report.cda_name'), rowSpan: 4, margin: [0, 20, 0] }, { text: vm.$t('bsri_report.center'), rowSpan: 4, margin: [10, 20, 0] }, { text: vm.$t('bsri_report.unit'), rowSpan: 4, margin: [10, 20, 0] }, { text: vm.$t('bsri_report.total_farmers'), rowSpan: 4, margin: [0, 20, 0] }, { text: vm.$t('bsri_report.Cultivation_method_and_subsidy_text'), style: 'tableHeader', colSpan: 10, alignment: 'center' }, { text: 'Header 3', style: 'tableHeader', alignment: 'center' }, { text: 'Header 3', style: 'tableHeader', alignment: 'center' }, 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss'],
          [{ text: 'Header 1', style: 'tableHeader', alignment: 'center' }, 'sss', 'sss', { text: 'Header 2', style: 'tableHeader', alignment: 'center' }, { text: 'Header 3', style: 'tableHeader', alignment: 'center' }, { text: vm.$t('bsri_report.Cultivation_Ropa_Cane_And_sathi_Crops_Text'), style: 'tableHeader', rowSpan: 2, colSpan: 2 }, 'sss', { text: vm.$t('bsri_report.Production_OfCane_Seed_In_Ropa_Method_text'), style: 'tableHeader', rowSpan: 2, colSpan: 2 }, 'sss', { text: vm.$t('bsri_report.Cultivation_of_Ropa_Cane'), style: 'tableHeader', rowSpan: 2, colSpan: 2 }, 'sss', { text: vm.$t('bsri_report.Cultivation_Of_Cane_In_Muri_Method'), style: 'tableHeader', rowSpan: 2, colSpan: 2 }, 'sss', { text: vm.$t('bsri_report.In_total'), style: 'tableHeader', rowSpan: 2, colSpan: 2, margin: [25, 15, 0] }, 'sss'],
          [{ text: 'rowSpan set to 3\nLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor' }, 'Sample value 2', 'Sample value 3', 'Sample value 3', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss', 'sss'],
          ['ssss', 'sss', 'sss', 'sssss', 'ssssss', { text: { text: vm.$t('bsri_report.land_quantity'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.amount'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.land_quantity'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.amount'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.land_quantity'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.amount'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.land_quantity'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.amount'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.land_quantity'), style: 'tableHeader' }, style: 'tableHeader' }, { text: { text: vm.$t('bsri_report.amount'), style: 'tableHeader' }, style: 'tableHeader' }]
        ]
        if (i18n.locale === 'bn') {
        newDatas.map((obj, index) => {
          rowData.push([vm.$n(index + 1), obj.cda_name, obj.centername_bn, vm.$n(obj.unit_id), vm.$n(obj.total_farmers), vm.$n(obj.Method_one_quantity), vm.$n(obj.Method_one_amount), vm.$n(obj.Method_two_quantity), vm.$n(obj.Method_two_amount), vm.$n(obj.Method_three_quantity), vm.$n(obj.Method_three_amount), vm.$n(obj.Method_four_quantity), vm.$n(obj.Method_four_amount), vm.$n(obj.Total_land_quantity), vm.$n(obj.Method_all_amount)])
        })
        totalresult.map((obj, index) => {
          rowData.push(['', vm.$t('globalTrans.total'), '', '', vm.$n(obj.total_farmers), vm.$n(obj.One_quantity.toFixed(2)), vm.$n(obj.One_amount.toFixed(2)), vm.$n(obj.Two_quantity.toFixed(2)), vm.$n(obj.Two_amount.toFixed(2)), vm.$n(obj.Three_quantity.toFixed(2)), vm.$n(obj.Three_amount.toFixed(2)), vm.$n(obj.Four_quantity.toFixed(2)), vm.$n(obj.Four_amount.toFixed(2)), vm.$n(obj.total_quantity.toFixed(2)), vm.$n(obj.total_amount.toFixed(2))])
        })
      } else {
        newDatas.map((obj, index) => {
          rowData.push([index + 1, obj.cda_name, obj.center_name, obj.unit_id, obj.total_farmers, obj.Method_one_quantity, obj.Method_one_amount, obj.Method_two_quantity, obj.Method_two_amount, obj.Method_three_quantity, obj.Method_three_amount, obj.Method_four_quantity, obj.Method_four_amount, obj.Total_land_quantity, obj.Method_all_amount])
        })
        totalresult.map((obj, index) => {
          rowData.push(['', vm.$t('globalTrans.total'), '', '', obj.total_farmers, obj.One_quantity.toFixed(2), obj.One_amount.toFixed(2), obj.Two_quantity.toFixed(2), obj.Two_amount.toFixed(2), obj.Three_quantity.toFixed(2), obj.Three_amount.toFixed(2), obj.Four_quantity.toFixed(2), obj.Four_amount.toFixed(2), obj.total_quantity.toFixed(2), obj.total_amount.toFixed(2)])
        })
      }
        var firstRows =
        [
          {
          table:
            {
              widths: [42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42],
              headerRows: 4,
              // keepWithHeaderRows: 1,
              body: rowData
            }
          }
        ]
      pdfContent.push(firstRows)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 15 : 13,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 13,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          main_head: {
            fontSize: 9
          },
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            fontSize: 9,
            color: 'black'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {
      }
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
